import request from '@/utils/request';

//创建运输信息
export function createBusinessShipping(data) {
  return request({
    url: '/order/waybill/save',
    method: 'post',
    data: data,
  });
}

//查询订单关联运输信息
export function selectBusinessShipping(orderId) {
  return request({
    url: '/order/waybill/get',
    method: 'get',
    params: { orderId },
  });
}
