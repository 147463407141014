import { render, staticRenderFns } from "./eventItem.vue?vue&type=template&id=8abf0032&scoped=true&"
import script from "./eventItem.vue?vue&type=script&lang=js&"
export * from "./eventItem.vue?vue&type=script&lang=js&"
import style0 from "./eventItem.vue?vue&type=style&index=0&id=8abf0032&lang=less&scoped=true&"
import style1 from "./eventItem.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8abf0032",
  null
  
)

export default component.exports