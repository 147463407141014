<template>
  <div
    class="active_item"
    ref="active_item"
    :id="'event'+item.eventId"
    :style="isEventType"
    v-if="item"
    v-quick="{
      dValue: item.eventId,
      nValue: '166d3815-1a33-4e3b-b27d-f3acb779b2f0',
      container: '.container_box',
    }"
  >
    <span class="active_item_time" :style="{
      textAlign: type === 'left' ? 'right' : ''
    }">{{ item.createTime && item.createTime.split(" ")[1] }}</span>
    <div class="activeBox">
      <member v-if="item.createPerson" :info="item.createPerson" :isShare="isShare" :size="38" />
      <div class="active_cont">
        <div class="flex_box event_name_box">
          <h6 class="event_name_box_h6">
            <i class="iconfont icon-yishoucang" v-if="item.collect"></i>
            <el-tooltip
              popper-class="ec-tooltip"
              effect="light"
              placement="top"
              v-if="item.createPerson.openId == businessUserId"
            >
              <div class="whoSeeBox" slot="content">
                <template v-if="item.eventLimit == 1">{{ $t('event.Only me') }}</template>
                <template v-else>
                  <div class="memberBox">
                    <template v-for="(row, idx) in item.visibleOpenUserDTO">
                      <member
                        :info="row"
                        :key="idx"
                        :size="20"
                      />
                    </template>
                  </div>
                  <span class="memberText">{{ $t('event.Can see') }}</span>
                </template>
              </div>
              <i 
                class="iconfont who" 
                :class="item.eventLimit == 1 ? 'icon-jinzijikejian' : item.eventLimit == 2 ? 'icon-bufenrenkejian' : ''"
              ></i>
            </el-tooltip>
            {{
              item.subject == "Shipping info updated"
                ? $t("order.Shipping info updated")
                : item.subject == "Checkpoint updated"
                ? $t("order.Checkpoint updated")
                : item.subject == "Shipping info created"
                ? $t("order.Shipping info created")
                : item.subject
            }}
          </h6>
          <el-popover
            v-if="isShare && isCeoAcount"
            placement="right-start"
            trigger="click"
            ref="eventPopover"
            popper-class="orderPopover"
            append-to-body
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true
            }"
          >
            <ul class="user_popover">
              <el-popover
                placement="bottom"
                width="420"
                ref="popover_reply"
                trigger="click"
                popper-class="orderPopover"
                @hide="item.commentInput = ''"
              >
                <li 
                  class="user_reply" 
                  slot="reference"
                  @click="eventQuillEditor(item, index)">
                  <i class="iconfont icon-huifu"></i>
                  <span>{{ $t("order.Reply") }}</span>
                </li>
                <div class="reply">
                  <quill-editor
                    :ref="`myQuillEditor-${item.eventId}`"
                    :id="`myQuillEditor-${item.eventId}`"
                    v-model="item.commentInput"
                    :key="editorKey"
                    :options="editorOption"
                    class="replyContent"
                  />
                </div>
                <div class="editorBtns">
                  <el-button
                    class="btn_cancel"
                    type="text"
                    size="small"
                    @click="close_Comment(item.eventId, index)"
                    >{{ $t("order.Cancel") }}</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="create_Comment"
                    >{{ $t("order.Confirm") }}</el-button
                  >
                </div>
              </el-popover>
              <li
                class="user_delete"
                @click="notesChange"
              >
                <i class="iconfont icon-bianji2"></i>
                <span>{{ $t("todo.Notes") }}</span>
              </li>
              <li
                class="user_delete"
                @click="followEventChange"
              >
                <i class="iconfont icon-shoucang"></i>
                <span>{{ !item.collect ? $t("home.Follow") : $t("home.Unfollow") }}</span>
              </li>
              <el-popover
                ref="popover"
                placement="right"
                trigger="click"
                :append-to-body="false"
                popper-class="orderPopover"
                @hide="tagPopoverShow = false"
                v-if="tagDataList.length">
                <li 
                  class="tagItem" 
                  v-for="(row, index) in tagDataList" 
                  :key="index"
                  @click="eventTagChange(row)">
                  <el-tag size="small" :color="row.color" class="eventTags">{{ row.content }}</el-tag>
                  <span class="gouhao" v-if="item.companyTagList && item.companyTagList.map(tag => tag.content).includes(row.content)">✅</span>
                </li>
                <li 
                  class="user_delete" 
                  slot="reference"
                  :class="tagPopoverShow ? 'active' : ''"
                  @click="tagPopoverShow = true">
                  <i class="iconfont icon-Plus"></i>
                  <span>{{ $t('order.Event tag') }}</span>
                  <i class="iconfont icon-a-Polygon3"></i>
                </li>
              </el-popover>
              <li
                v-if="item.userId == businessUserId"
                class="user_delete"
                @click="openDeleteBox(item, index)"
              >
                <i class="iconfont icon-shanchu"></i>
                <span>{{ $t("order.Delete") }}</span>
              </li>
              <li
                class="user_delete"
                @click="copyEvent(item)"
              >
                <i class="el-icon-document-copy" style="font-size: 16px;"></i>
                <span>{{ $t("Files.Copy Link") }}</span>
              </li>
            </ul>
            <div 
              class="moreBox" 
              :class="ActiveId == item.eventId ? 'active' : ''"
              slot="reference"
              @click.stop="
                onVisibleMore(
                  item,
                  index == visible_moreIndex ? -1 : index
                )
              "
              v-if="!item.keyEvent">
              <i class="iconfont icon-Ellipsis"></i>
            </div>
          </el-popover>
        </div>
        <div class="html_content_box">
          <div class="html_content_left_box">
            <conversation-bubble
              :key="item.eventId"
              :item="{
                msg: item.content,
                strId: item.eventId,
                ci: orderInfo.ci,
              }"
            ></conversation-bubble>
          </div>
        </div>
        <fileBlocks
          v-if="item.eventFileUrl"
          :embedUrl="item.eventFileUrl"
          :embedId="item.eventFileId"
          :ci="orderInfo && orderInfo.ci"
          background=""
        >
        </fileBlocks>

        <div class="tagEventBox" v-if="isShare">
          <el-popover 
            v-for="(row, index) in item.companyTagList" 
            :key="index"
            placement="top-start"
            trigger="hover"
            :open-delay="700"
            popper-class="eventTagUserPopover"
            :disabled="!row.openUserDTO">
            <div class="userTagBox" v-if="row.openUserDTO">
              <el-image :src="row.openUserDTO.avatarFileUrl || row.openUserDTO.avatarUrl" />
              <div class="content">
                <div class="title">
                  {{ $t('order.form') }}
                  <span>{{ row.openUserDTO.nickName }}</span>
                  {{ $t('home.Create') }}
                </div>
                <div class="time">{{ row.createTime }}</div>
              </div>
            </div>
            <el-tag 
              slot="reference"
              size="small" 
              :color="row.color" 
              :closable="(row.openUserDTO && row.openUserDTO.openId) == businessUserId"
              class="eventTags"
              @close="eventTagChange(row)"
            >{{ row.content }}</el-tag>
          </el-popover>
        </div>

        <div
          v-for="(comment, i) in item.commentList"
          :key="i"
          class="list_reply"
          :id="`comment${comment.commentId}`"
        >
          <div class="commentTemplate" v-show="i < commentShowNumber">
            <member v-if="comment.createPerson" :info="comment && comment.createPerson" :size="24"></member>
            <div class="commentBox">
              <div class="commentTextContent">
                <div class="commentName">
                  {{ comment.createPerson.nickName }}
                </div>
                <div class="commentTime">
                  {{ dayjs(comment.createTime).format("MM-DD HH:mm") }}
                  <el-popover
                    v-if="isShare && isCeoAcount"
                    placement="right-start"
                    width="82"
                    trigger="click"
                    popper-class="orderPopover"
                    ref="commentPopover"
                  >
                    <ul class="user_popover">
                      <li class="user_reply">
                        <el-popover
                          placement="bottom"
                          width="420"
                          trigger="click"
                          ref="popover_reply"
                          popper-class="orderPopover"
                          style="z-index: 0 !important"
                          @hide="item.commentInput = ''"
                        >
                          <div slot="reference" @click="commentQuillEditor(comment)">
                            <i class="iconfont icon-huifu"></i>
                            <span>{{ $t("order.Reply") }}</span>
                          </div>
                          <div class="reply">
                            <quill-editor
                              :id="`myQuillEditor-${comment.commentId}`"
                              :ref="`myQuillEditor-${comment.commentId}`"
                              v-model="item.commentInput"
                              :key="editorKey"
                              :options="editorOption"
                              class="replyContent"
                            />
                          </div>
                          <div class="editorBtns">
                            <el-button
                              class="btn_cancel"
                              type="text"
                              size="small"
                              @click="close_Comment(comment.commentId, i)"
                              >{{ $t("order.Cancel") }}
                            </el-button>
                            <el-button
                              type="primary"
                              size="small"
                              @click="
                                create_Comment(comment)
                              "
                              >{{ $t("order.Confirm") }}</el-button
                            >
                          </div>
                        </el-popover>
                      </li>

                      <li
                        v-if="
                          comment.createPerson &&
                          comment.createPerson.openId == businessUserId
                        "
                        class="user_delete"
                        @click="openDeleteBox(comment, index, i)"
                      >
                        <i class="iconfont icon-shanchu"></i>
                        <span>{{ $t("order.Delete") }}</span>
                      </li>
                    </ul>
                    <div 
                      class="moreBox" 
                      :class="ActiveId == comment.commentId ? 'active' : ''"
                      slot="reference"
                      @click.stop="commentClickPopover(comment)"
                      v-if="!item.keyEvent">
                      <i class="iconfont icon-Ellipsis"></i>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="html_reply_box">
                <div class="html_reply">
                  <div>
                    <!-- {{
                      comment.data.content.replace(/<[^>]+>/g, '')
                    }} -->
                    <conversation-bubble
                      :key="comment.commentId"
                      :item="{
                        msg: comment.content,
                        strId: comment.commentId,
                      }"
                    >
                      <template #call>
                        <span
                          v-if="comment.parentCommentId"
                          style="
                            color: rgba(55, 120, 173, 1);
                            font-size: 14px;
                          "
                          >@{{
                            onSearch(
                              item.commentList,
                              comment.parentCommentId,
                              item.createPerson
                            )
                          }}
                        </span>
                      </template>
                    </conversation-bubble>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div 
          v-if="item.commentList.length > commentShowNumber" 
          class="openMore"
          @click="commentShowNumber = 1000000"
        >{{ $tc('View the remaining comments', item.commentList.length - commentShowNumber) }}</div>
        <div 
          v-else-if="item.commentList.length > 4"
          class="openMore"
          @click="commentShowNumber = 4"
        >{{ $t('retract') }}</div>
      </div>
    </div>
    <el-popover
      :placement="type != 'left' ? 'left-start' : 'right-start'"
      trigger="hover"
      :disabled="notesShow"
      v-if="item.remark || notesShow"
      :content="item.remark"
      popper-class="remarkPopover">
      <div 
        slot="reference"
        class="notesBoxDialog" 
        :class="`${type} ${!notesShow ? 'tooltip' : 'form'}`" 
        v-if="notesShow || item.remark"
        @click.prevent="notesChange">
        <i v-if="!notesShow" class="iconfont icon-bianji2"></i>
        <div @click.stop v-else>
          <div class="title">
            {{ $t('todo.Notes') }}
            <i class="el-icon-close" @click="handleNotesClose"></i>
          </div>
          <el-input 
            type="textarea" 
            v-model="notesMessage" 
            resize="none"
            :placeholder="$t('Please enter the note content')" 
          />
          <div class="footer">
            <el-popconfirm 
              :confirm-button-text='$t("order.Confirms")'
              :cancel-button-text='$t("order.Cancel")'
              icon="el-icon-info"
              icon-color="#E6A23C"
              :title="$t('order.Do you want to delete this note')"
              @confirm="delteNotes">
              <toolTipIcon 
                slot="reference"
                icon="iconfont icon-shanchu" 
                :tooltipText="$t('order.Delete')"
              />
            </el-popconfirm>
            <div>
              <el-button class="cancel_button" size="small" @click="handleNotesClose">{{
                $t("Cancel")
              }}</el-button>
              <el-button type="primary" size="small" @click="createNotes">{{
                $t("Confirm")
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { createComment } from "@/api/comment";
import {
  deleteEvent,
  deleteCommen,
  followEvent,
  notesEvent,
  deleteNotesEvent,
  deleteEventTag,
  addEventTag
} from "@/api/event";
import { uploadFileUrl } from "@/api/files";

import quillEditor from "@/components/quillEditor/quillEditorRectify.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: 'eventItem',
  components: {
    quillEditor
  },
  props: {
    type: {
      type: String,
      default: 'left'
    },
    isShare: {
      type: Boolean,
      default: true
    },
    isCeoAcount: {
      type: Boolean | Object,
      default: true
    },
    item: {
      type: Object | null,
      default: null
    },
    index: {
      type: Number | String,
      default: 0
    },
    visible_moreIndex: {
      type: Number | String,
      default: 0
    },
    businessUserId: {
      type: Number | String,
      default: 0
    },
    orderInfo: {
      type: Object | null,
      default: null
    },
    tagDataList: {
      type: Array,
      default: () => []
    },
    activityList: {
      type: Array,
      default: () => []
    },
    ActiveId: {
      type: String | Number | null,
      default: null
    }
  },
  data() {
    return {
      dayjs: dayjs,
      notesShow: false,
      commentShowNumber: 4,
      editorKey: 1,
      notesBoxTooltipShow: false,
      notesMessage: "",
      tagPopoverShow: false,
      editorOption: {
        placeholder: this.$t("order.Write away"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
            ],
            [
              {
                color: [],
              },
            ],
            [
              {
                font: ["SimSun", "FangSong"],
              },
            ],
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    isEventType() {
      if(this.type === 'right') return {
        paddingLeft: '52%',
        paddingRight: '3%'
      }
      if(this.type === 'left') return {
        paddingRight: '52%',
        paddingLeft: '3%'
      }
    }
  },
  mounted() {
    document.addEventListener("click", () => {
      this.$emit("update:ActiveId", null);
      this.notesShow = false;
    })
    if(this.isShare) {
      this.$nextTick(() => {
        let quill = this.$refs[`myQuillEditor-${this.item.eventId}`].quill;
        quill.root.addEventListener(
          "paste",
          (evt) => {
            if(evt.clipboardData.getData("text")) {
              if(/^https?:\/\//.test(evt.clipboardData.getData("text"))) {
                evt.preventDefault();
                quill.setText(evt.clipboardData.getData("text"));
              }
            }
            if (
              evt.clipboardData &&
              evt.clipboardData.files &&
              evt.clipboardData.files.length
            ) {
              evt.preventDefault();
              [].forEach.call(evt.clipboardData.files, (file) => {
                if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                  return;
                }
                const data_up = new FormData();
                data_up.append("files", file);
                data_up.append("configCode", "file_event_content_img");
                uploadFileUrl(data_up)
                  .then((response) => {
                    if (!response) return;
                    let length = quill.getSelection().index;
                    quill.insertEmbed(length, "image", response[0].savePath);
                    quill.setSelection(length + 1);
                  })
                  .catch((error) => {});
              });
            }
          },
          false
        );
        if(this.item.commentList) {
          if(this.item.commentList.length) {
            this.item.commentList.forEach(comment => {
              let quill = this.$refs[`myQuillEditor-${comment.commentId}`][0].quill;
              quill.root.addEventListener(
                "paste",
                (evt) => {
                  if(evt.clipboardData.getData("text")) {
                    if(/^https?:\/\//.test(evt.clipboardData.getData("text"))) {
                      evt.preventDefault();
                      quill.setText(evt.clipboardData.getData("text"));
                    }
                  }
                  if (
                    evt.clipboardData &&
                    evt.clipboardData.files &&
                    evt.clipboardData.files.length
                  ) {
                    evt.preventDefault();
                    [].forEach.call(evt.clipboardData.files, (file) => {
                      if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                        return;
                      }
                      const data_up = new FormData();
                      data_up.append("files", file);
                      data_up.append("configCode", "file_event_content_img");
                      uploadFileUrl(data_up)
                        .then((response) => {
                          if (!response) return;
                          let length = quill.getSelection().index;
                          quill.insertEmbed(length, "image", response[0].savePath);
                          quill.setSelection(length + 1);
                        })
                        .catch((error) => {});
                    });
                  }
                },
                false
              );
            })
          }
        }
        if(this.item.remark) {
          this.notesMessage = JSON.parse(JSON.stringify(this.item.remark));
        }
      })
    }
  },
  methods: {
    copyEvent(row) {
      const theClipboard = navigator.clipboard;
      theClipboard.writeText(`${location.href.split("?")[0]}?orderId=${this.orderInfo.orderId}&eventId=${row.eventId}`).then(() => {
        this.$message.success(this.$t('home.Copysucceeded'));
        document.body.click();
      });
    },
    handleMouseEnter() {
      this.notesBoxTooltipShow = true;
    },
    handleMouseLeave() {
      this.notesBoxTooltipShow = false;
    },
    eventTagChange(row) {
      if(this.item.companyTagList && this.item.companyTagList.map(tag => tag.content).includes(row.content)) {
        let indexId = this.item.companyTagList.map(tag => tag.content).indexOf(row.content);
        let rowItem = this.item.companyTagList.filter((tag, index) => index == indexId)[0];
        if(this.businessUserId == rowItem.openUserDTO.openId) {
          this.$confirm(
            this.$t("order.Do you want to cancel this label"),
            this.$t("Tips"),
            {
              confirmButtonText: this.$t("order.Confirms"),
              cancelButtonText: this.$t("order.Cancel"),
              type: "warning",
            }
          ).then(() => {
            deleteEventTag({
              eventId: this.item.eventId,
              tagId: row.tagId
            }).then(() => {
              rowItem.createPerson = null;
              this.item.companyTagList.splice(indexId, 1);
            })
          }).catch(() => {});
        }
      } else {
        addEventTag({
          eventId: this.item.eventId,
          tagId: row.tagId
        }).then(() => {
          if(this.item.companyTagList == null) {
            this.item.companyTagList = [];
          }
          let timeZone = Number(this.userInfo.timeZone.split('GMT')[1]);
          this.item.companyTagList.push({
            ...row,
            openUserDTO: {
              ...this.userInfo
            },
            createTime: dayjs(this.getTimeZooe(timeZone)).format("YYYY-MM-DD HH:mm:ss"),
            createPerson: this.businessUserId
          });
        })
      }
    },
    getTimeZooe(data){
      let time = new Date(); //获取时间
      // 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
      let dataOffset = new Date().getTimezoneOffset()*60000
      // 获取格林威治时间 
      let utc = time.getTime()+dataOffset;  // 两个时间戳
      // 拿格林威治时间去反推指定地区时间    
      let newTime = utc+3600000*data;  // 
      let times = new Date(newTime);
      return times
    },
    notesChange() {
      document.body.click();
      setTimeout(() => {
        if(this.notesShow) return;
        this.notesShow = true;
        this.notesMessage = JSON.parse(JSON.stringify(this.item.remark));
      }, 100);
    },
    handleNotesClose() {
      this.notesShow = false;
      this.notesMessage = "";
    },
    delteNotes() {
      deleteNotesEvent(this.item.eventId).then(() => {
        this.$message.success(this.$t('todo.Notes')+this.$t('home.Delete')+this.$t('success'));
        this.item.remark = "";
        this.notesShow = false;
      });
    },
    createNotes() {
      if(!this.notesMessage) {
        this.$message.warning(this.$t('Please enter the note content'));
        return;
      }
      notesEvent({
        eventId: this.item.eventId,
        content: this.notesMessage
      }).then(() => {
        this.$message.success(this.$t('todo.Notes')+this.$t('success'));
        this.item.remark = this.notesMessage;
        this.handleNotesClose();
      })
    },
    followEventChange() {
      followEvent(this.item.eventId).then(() => {
        this.$message.success(
          (!this.item.collect ? this.$t('home.Follow') : this.$t('home.Unfollow'))+this.$t('success')
        );
        this.item.collect = !this.item.collect;
        document.body.click();
      }).finally(() => {})
    },
    eventQuillEditor(item, index) {
      setTimeout(() => {
        document.querySelector(`#myQuillEditor-${this.item.eventId}`).querySelector(".ql-editor").focus();
      }, 100)
    },
    commentQuillEditor(row) {
      setTimeout(() => {
        document.querySelector(`#myQuillEditor-${row.commentId}`).querySelector(".ql-editor").focus();
      }, 100)
    },
    close_Comment(id) {
      document.body.click();
      this.$refs.eventPopover.showPopper = true;
      this.item.commentInput = "";
      this.editorKey += 1;
      this.onActiveId(id);
    },
    create_Comment(comment) {
      var data_comment = {
        content: this.item.commentInput,
        eventId: this.item.eventId,
        parentCommentId: comment?.commentId,
      };
      this.$emit("update:visible_moreIndex", -1);
      this.item.commentInput = "";
      this.editorKey += 1;
      createComment(data_comment).then((response) => {
        if (!response) return;
        document.body.click();
      });
    },
    onActiveId(id) {
      this.$emit("update:ActiveId", id);
    },
    onClose() {
      this.$emit("update:visible_moreIndex", -1);
    },
    openDeleteBox(item, index, i) {
      document.body.click();
      this.$confirm(
        this.$t("event.This event will be deleted"),
        this.$t("event.Notification"),
        {
          confirmButtonText: this.$t("order.Confirms"),
          cancelButtonText: this.$t("order.Cancel"),
          type: "warning",
        }
      ).then(() => {
        if (i === undefined) {
          var data_eventDelete = {
            eventId: item.eventId,
          };
          deleteEvent(data_eventDelete).then((response) => {
            if (!response) return;
            this.$emit("deleteEventData", this.activityList.indexOf(item), 1);
            this.$message.success(this.$t("event.Delete successfully"));
          }).finally(() => {
            this.onClose();
            document.body.click();
          });
        } else {
          var data_eventDelete = {
            commentId: item.commentId,
          };
          deleteCommen(data_eventDelete).then((response) => {
            if (!response) return;
            let arr = [];
            this.activityList.forEach((item, idx) => {
              let obj = JSON.parse(JSON.stringify(item));
              if(idx == index) {
                obj.commentList = obj.commentList.filter((row, ei) => ei != i)
              }
              arr.push(obj)
            })
            this.$emit("update:activityList", arr);
            this.$message.success(this.$t("event.Delete successfully"));
          }).finally(() => {
            this.onClose();
            document.body.click();
          });
        }
      }).catch(() => {
        this.onClose();
        this.$message.info(this.$t("event.Delete cancel"));
        document.body.click();
      });
    },
    onVisibleMore(data, index) {
      if(this.$refs.commentPopover) {
        this.$refs.commentPopover.forEach((item) => {
          item.doClose();
        })
      }
      document.body.click();
      if(this.$refs.eventPopover.showPopper) {
        this.$emit("update:ActiveId", null);
      } else {
        this.$emit("update:ActiveId", data.eventId);
        this.$emit("update:visible_moreIndex", index);
      }
    },
    commentClickPopover(data) {
      if(this.$refs.commentPopover) {
        this.$refs.commentPopover.forEach((item) => {
          item.doClose();
        })
      }
      document.body.click();
      this.$emit("update:ActiveId", null);
      this.$nextTick(() => {
        this.$emit("update:ActiveId", data.commentId);
      })
    },
    onSearch(obj, parentCommentId, itemVo) {
      let item = obj.find((res) => {
        return res.commentId == parentCommentId;
      });
      return item && item.createPerson.nickName
        ? item && item.createPerson.nickName
        : itemVo.nickName;
    },
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>

<style>
.orderPopover {
  padding: 6px 0;
  min-width: auto;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}
.notesBoxPopover {
  box-shadow: none;
  border: none;
}
.eventTagUserPopover {
  padding: 10px 16px 10px 10px;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  border: none;
  border-radius: 4px;
}
.remarkPopover {
  max-width: 250px;
  box-shadow: 0 0 20px #f1f1f1;
  border: none;
  padding: 12px 16px;
}
</style>