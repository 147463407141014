<template>
  <div
    class="active_item"
    :id="'event'+item.eventId"
    :style="isEventType"
    v-if="item"
  >
    <span class="active_item_time" :style="{
      textAlign: type === 'left' ? 'right' : ''
    }">{{ item.createTime && item.createTime.split(" ")[1] }}</span>
    <div class="activeBox">
      <i class="iconfont avatar" :class="iconComputed"></i>
      <div class="active_cont">
        <div class="flex_box event_name_box">
          <h6 class="event_name_box_h6">
            {{ systemName }}
          </h6>
        </div>
        <div class="html_content_box" v-if="item.contentJson">
          <div class="conversationBubble">
            <div class="headerBox">
              <div class="left">
                <member v-if="item.contentJson.type != 4" :info="item.createPerson" :isShare="isShare" :size="24" />
                <div class="content systemEventTitle" v-if="item.createPerson">
                  <span class="name" v-if="item.contentJson.type != 4">{{ item.createPerson.nickName }}</span>
                  <span class="name" v-else>{{ $t('memberList.manage') }}</span>
                  <span class="behavior">{{ behaviorComputed }}</span>
                  <span class="title">{{ titleTypeComputed }}</span>
                </div>
              </div>
              <div class="detail" v-if="(item.contentJson.type == 1 || item.contentJson.type == 2) && item.contentJson.status == 2" @click="detailOpen = !detailOpen">
                {{ $t("home.detailText") }}
                <i :class="!detailOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
              </div>
            </div>
            <div class="clockInContent" v-if="item.contentJson.type == 3">
              <div class="text" v-if="computedNodeList(item.contentJson.oldProgress)">
                {{ $t('order.orderStatusForm') }}
                {{ computedNodeList(item.contentJson.oldProgress).name }}
                {{ $t('order.change to') }}
                <span>{{ computedNodeList(item.contentJson.newProgress).name }}</span>
              </div>
              <fileBlocks
                v-for="(row, index) in item.contentJson.urlList"
                :key="index"
                :embedUrl="row.savePath"
                :embedId="row.fileId"
                :ci="orderInfo && orderInfo.ci"
              >
              </fileBlocks>
            </div>
            <div class="changeContent" v-if="item.contentJson.type == 1">
              <div v-if="item.contentJson.status == 2">
                <div class="itemTwo" v-for="(row, index) in item.contentJson.data.filter(item => item.change)" :key="index">
                  <span>{{ ($route.query.orderId ? String($store.state.user.orderAlias) == '0' : orderAlias == '0') ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? ($route.query.orderId ? $isOrderPITitle() : isOrderPITitle) : row.fieldName }}</span>
                  {{ $t('order.form') }}
                  {{ fieldValueComputed(row) }}
                  {{ $t('order.change to') }}
                  <span>{{ fieldValue1Computed(row) }}</span>
                </div>
              </div>
              <div class="changeBox" v-if="item.contentJson.status == 1 || detailOpen">
                <div class="item" v-for="(row, index) in ($route.query.orderId ? String($store.state.user.orderAlias) == '0' : orderAlias == '0') ? item.contentJson.data.filter((tt, ii) => ii < 6) : item.contentJson.data.filter(item => item.fieldName != 'CI号' && item.fieldName != 'CI number') " :key="index">
                  <span>
                    {{ ($route.query.orderId ? String($store.state.user.orderAlias) == '0' : orderAlias == '0') ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? ($route.query.orderId ? $isOrderPITitle() : isOrderPITitle) : row.fieldName }}
                    <span class="tag" v-if="row.change">{{ $t('order.Change') }}</span>
                  </span>
                  <span>{{ fieldValue1Computed(row) }}</span>
                </div>
              </div>
              <fileBlocks 
                v-if="item.eventFileUrl"
                style="margin: 10px 0 5px 0;"
                :embedUrl="item.eventFileUrl"
                :embedId="item.eventFileId"
                :ci="orderInfo && orderInfo.ci"
              />
            </div>
            <div class="shipContent" ref="shipContent" v-if="item.contentJson.type == 2">
              <div v-if="item.contentJson.status == 2">
                <div class="itemTwo" v-for="(row, index) in item.contentJson.data.filter(item => item.change)" :key="index+'two'">
                  <span>{{ row.fieldName }}</span>
                  {{ $t('order.form') }}
                  {{ shipFieldValueComputed(row) }}
                  {{ $t('order.change to') }}
                  <span>{{ shipFieldValue1Computed(row) }}</span>
                </div>
              </div>
              <div class="shipBox" v-if="item.contentJson.status == 1 || detailOpen">
                <div class="item" v-for="(row, index) in item.contentJson.data" :key="index">
                  <span>
                    {{ row.fieldName }}
                    <span class="tag" v-if="row.change">{{ $t('order.Change') }}</span>
                  </span>
                  <span>
                    {{ shipFieldValue1Computed(row).split(" ")[0] }}
                    <span v-if="shipFieldValue1Computed(row).split(' ')[1]">
                      {{ shipFieldValue1Computed(row).split(' ')[1] }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="purchaseBox" @click="openPurchaseDialog" v-if="item.contentJson.purchase">
            <div class="iconBox">
              <i class="iconfont icon-caigoudanguanli"></i>
            </div>
            {{ item.contentJson.purchase.purchaseNo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: 'eventItem',
  props: {
    type: {
      type: String,
      default: 'left'
    },
    isShare: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object | null,
      default: null
    },
    orderInfo: {
      type: Object | null,
      default: null
    },
    shippingInfo: {
      type: Object | null,
      default: null
    }
  },
  data() {
    return {
      dayjs: dayjs,
      detailOpen: false,
      orderAlias: ""
    }
  },
  computed: {
    ...mapGetters(['orderProgress', 'country', 'allNodeList', 'businessUserId']),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    isEventType() {
      if(this.type === 'right') return {
        paddingLeft: '52%',
        paddingRight: '3%'
      }
      if(this.type === 'left') return {
        paddingRight: '52%',
        paddingLeft: '3%'
      }
    },
    systemName() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) return `${this.$isOrderTitle() || this.isOrderTitle}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 2) return `${this.$t("home.waybill")}：${this.shippingInfo?.bl}`
        if(content.type == 3) return `${this.$t("home.clock in")}：${this.orderInfo.ci || this.orderInfo.pi}`
        if(content.type == 4) return this.$tc('order.Order node change', this.businessUserId ? this.$isOrderTitle() : this.isOrderTitle)
      }
    },
    iconComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return "icon-dingdan1";
        } else if(content.type == 2) {
          return "icon-yundan";
        } else if(content.type == 3) {
          return "icon-daka";
        } else {
          return "icon-shuaxin";
        }
      }
    },
    behaviorComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 2) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 3) {
          return this.$t("order.Clocked");
        } else  {
          return this.$t("order.Changed");
        }
      }
    },
    titleTypeComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return this.$tc("order.OrderInfo", this.businessUserId ? this.$isOrderTitle() : this.isOrderTitle);
        } else if(content.type == 2) {
          return this.$t("order.shipInfo");
        } else if(content.type == 3) {
          return this.$tc("order.orderHistory", this.businessUserId ? this.$isOrderTitle() : this.isOrderTitle);
        } else {
          return this.$tc("order.Order node", this.businessUserId ? this.$isOrderTitle() : this.isOrderTitle);
        }
      }
    },
    isOrderTitle() {
      return this.$isFindData(JSON.parse(localStorage.getItem('shareOrderAliasData')), localStorage.getItem('shareOrderAlias'), 'value', 'key') 
    },
    isOrderPITitle() {
      if(localStorage.getItem('shareOrderAlias') != '0') {
        return window.vm.$tc('Project name', this.$isFindData(JSON.parse(localStorage.getItem('shareOrderAliasData')), localStorage.getItem('shareOrderAlias'), 'value', 'key'))
      } else {
        return 'PI'
      }
    },
    fieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue1 || row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue1 || row.fieldValue;
        }
      }
    },
    fieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue;
        }
      }
    },
    shipFieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue;
        }
      }
    },
    shipFieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue1 == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue1 || row.fieldValue || "";
        }
      }
    }
  },
  mounted() {
    this.orderAlias = localStorage.getItem('shareOrderAlias');
  },
  methods: {
    openPurchaseDialog() {
      this.$myEvent.emit("openPurchase", {
        mode: 3,
        row: this.item.contentJson.purchase
      });
    },
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
.html_content_box {
  width: 100%;
  flex-direction: column;
}
.conversationBubble {
  background: #F7F9FC;
  border-radius: 4px;
  padding: 12px 12px;
  width: 100%;
  .headerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      .content {
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        .name {
          color: #122545;
          font-weight: 500;
        }
        .behavior {
          color: #637381;
        }
        .title {
          color: #076F49;
          font-weight: 500;
        }
      }
    }
    .detail {
      font-size: 12px;
      color: #637381;
      cursor: pointer;
    }
  }
  .clockInContent {
    margin-left: 3px;
    margin-top: 10px;
    margin-bottom: 4px;
    .text {
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      span {
        color: #076F49;
      }
    }
  }
  .changeContent {
    margin-left: 3px;
    .itemTwo {
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 10px;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
    .changeBox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        span {
          &:first-child {
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            .tag {
              background: #ffffff;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 0;
              color: #076F49;
            }
          }
          &:last-child {
            font-size: 14px;
            line-height: 16px;
            margin-top: 2px;
            color: #122545;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .shipContent {
    margin-left: 3px;
    .itemTwo {
      width: 100%;
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 10px;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
    .shipBox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        span {
          &:first-child {
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            .tag {
              background: #ffffff;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 0;
              color: #076F49;
            }
          }
          &:last-child {
            font-size: 14px;
            line-height: 16px;
            margin-top: 2px;
            color: #122545;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              font-size: 12px;
              color: #637381;
            }
          }
        }
      }
    }
  }
}
.purchaseBox {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #122545;
  font-weight: 600;
  gap: 9px;
  margin-top: 10px;
  .iconBox {
    width: 29px;
    height: 29px;
    background: #F7F9FC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    i {
      font-size: 13px;
      color: #076F49;
    }
  }
}
</style>