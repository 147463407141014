<template>
  <div
    id="shareOrderBox"
    class="shareOrderBox"
    :style="{
      zoom: isExternal ? '.7' : '1',
    }"
  >
    <img class="background" :src="backgroundImage || shareBackBgUrl" alt="" />
    <div class="backRadius"></div>
    <div class="bodyBox">
      <div class="header">
        <img
          :src="platformLogo || shareCompanyLogo"
        />
        <div class="userInfoBox" v-if="shareUserInfo">
          <img :src="shareUserInfo.avatarFileUrl" />
          <div class="name">{{ shareUserInfo.nickName }}{{ $t('order.share') }}</div>
        </div>
      </div>
      <div class="bodyTitle" :style="{
        color: titleColor || fontColor,
        fontSize: fontSize+'px'
      }">{{ backgroundText || shareBackText }}</div>
      <div class="seeMore" @click="goOrderDetail">
        {{ $t("home.seeMore") }}
        <i class="el-icon-right"></i>
      </div>
      <div class="mapOrderBox">
        <div class="mapHistoryBox" :style="{
          height: pageType == 1 || type == 1 ? '580px' : '441px'
        }">
          <div class="orderNoText">
            {{ $tc('order.OrderNo', !pageToken ? $isOrderTitle() : isOrderTitle) }}: {{ orderInfo && (orderInfo.ci || orderInfo.pi) }}
          </div>
          <mapTrack
            :orders="[
              {
                orderId: (orderInfo && orderInfo.orderId) || 200000151368,
              },
            ]"
            :domId="'map_share' + (orderInfo && orderInfo.orderId)"
          />
          <div class="historyBox" v-if="orderInfo">
            <template v-for="(item, index) in orderInfo.progressList">
              <div
                :key="index"
                class="item"
                :class="{
                  active: item.date
                }"
              >
                <div class="icon">
                  <i :class="`iconfont ${computedNodeList(item.progress).icon}`"></i>
                </div>
                <div class="text">{{ computedNodeList(item.progress).name }}</div>
              </div>
              <div
                :key="index+'line'"
                class="line"
                :class="{ active: item.date }"
                v-if="index != orderInfo.progressList.length - 1"
              ></div>
            </template>
          </div>
        </div>
        <div class="orderInfoBox" v-if="pageType == 1 || type == 1">
          <div class="title">{{ $t("order.Transportate detail") }}</div>
          <div class="purchaseCompany" v-if="orderInfo">
            <div class="purchaseCompanyInfo">
              <span>{{ orderInfo.purchase.aliasName || orderInfo.purchase.name }}</span>
              {{ $t("order.Buyer") }}
            </div>
            <el-image
              fit="cover"
              :src="orderInfo.purchase.companyLogoUrl"
            />
          </div>
          <div class="obw-grid" v-if="orderInfo">
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Status") }}</div>
              <div class="lb-a2">
                <div class="flexAlignCenter" style="gap: 3px">
                  <div class="dropState" :class="stateDrop(orderInfo)" />
                  {{ computedNodeList(orderInfo.progress).name }}
                </div>
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("order.Value") }}</div>
              <div class="lb-a2">
                {{ orderInfo.currency }}
                {{ orderInfo.amount }}
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Create Time") }}</div>
              <div class="lb-a2">
                {{ orderInfo.createTime }}
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Supplier") }}</div>
              <div class="lb-a2">
                {{
                  orderInfo.vendor.aliasName
                }}
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("order.Buyer") }}</div>
              <div class="lb-a2">
                {{
                  orderInfo.purchase.aliasName
                }}
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Forwarder") }}</div>
              <div class="lb-a2">
                <template v-for="(item, index) in orderInfo.forwarderList">
                  {{ item.aliasName }}{{ index != (orderInfo.forwarderList.length - 1) ? ',' : '' }}
                </template>
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Ship info") }}</div>
              <div class="lb-a2" v-if="shippingInfo">
                {{ shippingInfo.vessel || '_ _' }} / {{ shippingInfo.voyage || '_ _' }}
              </div>
              <div class="lb-a2" v-else>
                _ _
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Weight/Dimension") }}</div>
              <div class="lb-a2" v-if="shippingInfo">
                {{ shippingInfo.weight || '_ _' }} / {{ shippingInfo.dimension || '_ _' }}
              </div>
              <div class="lb-a2" v-else>
                _ _
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Port of Loading") }}</div>
              <div class="lb-a2" v-if="shippingInfo">
                {{ shippingInfo.pol || '_ _' }}
              </div>
              <div class="lb-a2" v-else>
                _ _
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Quantity") }}</div>
              <div class="lb-a2" v-if="shippingInfo">
                {{ shippingInfo.qty || '_ _' }}
              </div>
              <div class="lb-a2" v-else>
                _ _
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Port of discharge") }}</div>
              <div class="lb-a2" v-if="shippingInfo">
                {{ shippingInfo.pod || '_ _' }}
              </div>
              <div class="lb-a2" v-else>
                _ _
              </div>
            </div>
            <div class="left-block">
              <div class="lb-a1">{{ $t("tracking.Destination") }}</div>
              <div class="lb-a2" v-if="shippingInfo">
                {{ shippingInfo.destination || '_ _' }}
              </div>
              <div class="lb-a2" v-else>
                _ _
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="orderOtherBox" v-if="pageType == 0 || type == 0">
        <div v-loading="loading_event" class="eventBox">
          <el-empty
            :description="$t('order.No message')"
            :image="$emptyImage"
            :image-size="90"
            v-if="activityList && !activityList.length"
          ></el-empty>
          <div
            class="eventListBox"
            v-for="(item, index) in activityList"
            :key="index"
          >
            <div class="data_file" v-if="isShowDateFun(item, index)">
              <h4 class="item_date">
                {{ item.createTime && item.createTime.split(" ")[0] }}
              </h4>
            </div>
            <div class="eventLeft">
              <eventItem
                v-if="!item.isSystem"
                :isShare="false"
                :item="item"
                :index="index"
                :orderInfo="orderInfo"
              />
              <systemEventItem
                v-else
                :item="item"
                :isShare="false"
                :orderInfo="orderInfo"
                :shippingInfo="shippingInfo"
              />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="orderInfoBox">
            <div class="title">{{ $tc('order.orderDetail', !pageToken ? $isOrderTitle() : isOrderTitle) }}</div>
            <div class="logoDetail">
              <el-avatar :src="companyLogoUrl" fit="cover" />
              <div class="content">
                <div class="label">{{ $tc("order.OrderNo", !pageToken ? $isOrderTitle() : isOrderTitle) }}</div>
                <div class="text">
                  {{
                    orderInfo && (orderInfo.ci ? orderInfo.ci : orderInfo.pi)
                  }}
                </div>
              </div>
            </div>
            <div class="infoRowDetail" v-if="orderInfo">
              <div class="rowItem">
                <p>{{ $t("order.CreateTiem") }}</p>
                <p>{{ orderInfo.createTime }}</p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Value") }}</p>
                <p>
                  {{ orderInfo.currency }}
                  {{ orderInfo.amount }}
                </p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Status") }}</p>
                <p>{{ computedNodeList(orderInfo.progress).name }}</p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Buyer") }}</p>
                <p>
                  {{ orderInfo.purchase.aliasName || orderInfo.purchase.name }}
                </p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Forwarder") }}</p>
                <div class="forwarderList">
                  <div
                    v-for="(item, index) in orderInfo.forwarderList"
                    :key="index"
                    class="item"
                  >
                    {{ item && item.name }}
                    {{
                      index == orderInfo.forwarderList &&
                      orderInfo.forwarderList.length - 1
                        ? ""
                        : index === orderInfo.forwarderList.length - 1
                        ? ""
                        : "，"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="waybillInfoBox">
            <div class="title">{{ $t("order.shipDetail") }}</div>
            <div class="infoRowDetail">
              <div class="rowItem" v-if="shippingInfo && shippingInfo.shippingType == '0'">
                <p>{{ $t("order.Ship info") }}</p>
                <p
                  v-if="shippingInfo.vessel != '' || shippingInfo.voyage != ''"
                >
                  {{ shippingInfo.vessel }}/{{ shippingInfo.voyage }}
                </p>
                <p v-else>____</p>
              </div>
              <div class="rowItem" v-if="shippingInfo && shippingInfo.shippingType == '1'">
                <p>{{ $t("order.Air info") }}</p>
                <p
                  v-if="
                    (shippingInfo && shippingInfo.flight != '') ||
                    (shippingInfo && shippingInfo.flightDate != '')
                  "
                >
                  {{ shippingInfo && shippingInfo.flight }}/{{
                    $dayjs(shippingInfo && shippingInfo.flightDate).format(
                      "YYYY-MM-DD"
                    )
                  }}
                </p>
                <p v-else>____</p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Weight/Dimension") }}</p>
                <p
                  v-if="
                    shippingInfo && (shippingInfo.weight != '' || shippingInfo.dimension != '')
                  "
                >
                  {{ shippingInfo.weight }}{{ shippingInfo.weightUnit }}/
                  {{ shippingInfo.dimension }}{{ shippingInfo.dimensionUnit }}
                </p>
                <p v-else>____</p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Quantity") }}</p>
                <p v-if="shippingInfo && shippingInfo.qty != ''">
                  {{ shippingInfo.qty }}{{ shippingInfo.qtyUnit }}
                </p>
                <p v-else>____</p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Port of Loading") }}</p>
                <p v-if="shippingInfo && shippingInfo.pol != ''">
                  {{ shippingInfo.pol }}
                </p>
                <p v-else>____</p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Port of discharge") }}</p>
                <p v-if="shippingInfo && shippingInfo.pod != ''">
                  {{ shippingInfo.pod }}
                </p>
                <p v-else>____</p>
              </div>
              <div class="rowItem">
                <p>{{ $t("order.Destination") }}</p>
                <p v-if="shippingInfo && shippingInfo.destination != ''">
                  {{ shippingInfo.destination }}
                </p>
                <p v-else>____</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="welcomeTip" v-if="shareUserInfo && isWelcome">
      <div class="welcomeBox">
        <div class="left">
          <img :src="platformLogo || shareCompanyLogo" class="logo" />
          <div class="title">{{ $t('Welcome to Echola') }}</div>
          <div class="describe">{{ $t('Come on in Order Info') }}</div>
        </div>
        <div class="right">
          <el-button round @click="isWelcome = false">{{ $t('Continue browsing this page') }}</el-button>
          <el-button type="primary" @click="goLogin" round>{{ $t('Log in now') }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getOrderDetails,
  getShareOrderInfo,
  getShareWaybillInfo,
  getShareEventList,
  getShareInfo,
  getShareShipInfo,
  getAllNodeList
} from "@/api/order";
import { getDict } from "@/api/common";
import { getEventPageList } from "@/api/event";
import { selectBusinessShipping } from "@/api/shipping";

import eventItem from "../order/components/eventItem";
import systemEventItem from "../order/components/systemEventItem.vue";

export default {
  name: "shareOrderLink",
  components: {
    systemEventItem,
    eventItem,
  },
  props: {
    type: {
      type: Number | String,
      default: null,
    },
    backgroundImage: {
      type: String,
      default: "",
    },
    backgroundText: {
      type: String,
      default: "",
    },
    orderId: {
      type: Number | String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      isWelcome: true,
      pageType: null,
      pageToken: null,
      isExternal: this.backgroundImage,
      orderInfo: null,
      shippingInfo: null,
      data_event: {
        currentPage: 1,
        pageSize: 5,
      },
      beforeScrollTop: 0,
      companyLogoUrl: "",
      activityList: [],
      loading_event: false,
      eventTotal: 0,
      shareBackBgUrl: "",
      shareBackText: "",
      shareUserInfo: null,
      copyAllNodeList: [],
      orderAliasData: [],
      shareCompanyLogo: "",
      fontSize: "",
      fontColor: ""
    };
  },
  created() {
    this.pageType = this.$route.query.type || null;
    this.pageToken = this.$route.query.token || null;
    if(this.pageToken) {
      this.$i18n.locale = this.$route.query.lang;
      this.$store.dispatch("user/setLanguage", this.$route.query.lang);
      localStorage.setItem("lang", this.$route.query.lang);
    }
    if(this.pageToken) {
      this.getAllNodeList();
      this.getDictData();
    }
  },
  mounted() {
    this.getOrderDetails();
  },
  computed: {
    ...mapGetters(["allNodeList", "platformLogo"]),
    computedNodeList() {
      return function (type) {
        return (this.allNodeList.length ? this.allNodeList : this.copyAllNodeList).find(item => item.type == type);
      }
    },
    isOrderTitle() {
      return this.$isFindData(this.orderAliasData, this.shareUserInfo?.company?.orderAlias || "0", 'value', 'key')
    },
    stateDrop() {
      return function ({progress = 0, progressList = []}) {
        if(progressList.length) {
          if(progress == progressList[0].progress) {
            return 'producing'
          } else if(progress == progressList[progressList.length]?.progress) {
            return 'destination'
          } else {
            return 'inTransit'
          }
        } else {
          return ""
        }
      }
    }
  },
  provide() {
    return {
      isOneClickTranslate: () => {
        return false
      },
      isShare: () => {
        return true
      }
    };
  },
  methods: {
    getAllNodeList() {
      getAllNodeList().then((response) => {
        this.copyAllNodeList = response;
      });
    },
    getDictData() {
      getDict().then((response) => {
        this.orderAliasData = response['ORDER_ALIAS'];
        localStorage.setItem("shareOrderAliasData", JSON.stringify(response['ORDER_ALIAS']));
      });
    },
    goLogin() {
      this.$router.push({
        path: `/login`
      });
    },
    handleScroll () {
      // 距顶部
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 可视区高度
      var clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 滚动条总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      // 确定滚动方向
      if (scrollTop >= this.beforeScrollTop) {
        // 滚动触底
        if (scrollTop + clientHeight >= scrollHeight) {
          if(this.pageType) {
            this.data_event.currentPage += 1;
            this.getEventList();
          }
        }
      }
      this.beforeScrollTop = scrollTop;
    },
    throttle (f, w) {
      let t = null;
      return function () {
        if (t) {
          return;
        }
        t = setTimeout(() => {
          //执行操作(使用apply重新绑定this的指向)
          f.apply(this, arguments);
          t = null;
        }, w);
      };
    },
    goOrderDetail() {
      this.$router.push({
        path: `/order/orderDetails`,
        query: {
          orderId: this.orderInfo.orderId
        },
      });
    },
    getShareInfo() {
      getShareInfo(this.pageToken).then((data) => {
        this.shareBackBgUrl = data.imagePath;
        this.shareBackText = data.title;
        this.shareUserInfo = data.createUser;
        this.shareCompanyLogo = data.companyLogoUrl;
        this.fontSize = data.titleSize || 32;
        this.fontColor = data.titleColor || '#ffffff';
        localStorage.setItem("shareOrderAlias", data.createUser.company.orderAlias || 0);
      });
      document.body.style.background = '#f7f9fc';
    },
    getOrderDetails() {
      let api = this.pageType === null ? getOrderDetails : getShareOrderInfo;
      if (this.pageType != null) {
        this.data_event.pageSize = 20;
        this.getShareInfo();
      }
      api(this.pageToken || this.orderId)
        .then((response) => {
          if (!response) return;
          this.orderInfo = response;
          if (this.companyCurrentType == "1") {
            this.companyLogoUrl = this.orderInfo.purchase.companyLogoUrl;
          } else if (this.companyCurrentType == "0") {
            this.companyLogoUrl = this.orderInfo.vendor.companyLogoUrl;
          } else {
            this.companyLogoUrl = this.orderInfo.purchase.companyLogoUrl;
          }
          this.getEventList();
          this.getSelectBusinessShipping();
          document.onscroll = (e) => {
            this.throttle(this.handleScroll(), 10000);
          }
        })
        .catch((error) => {});
    },
    getEventList() {
      this.loading_event = true;
      let api = this.pageType === null ? getEventPageList : getShareEventList;
      api(
        this.pageType === null
          ? {
              ...this.data_event,
              orderId: this.orderId,
            }
          : {
              ...this.data_event,
              token: this.pageToken,
            }
      )
        .then((response) => {
          if (!response) return;
          this.activityList = this.activityList.concat(
            response.list.map((item, index) => {
              item.content = item.contentJson || item.content;
              return {
                ...item,
                isSystem: typeof item.content == "object",
                userId: item.createPerson.openId,
              };
            })
          );
          this.eventTotal = response.total;
        })
        .finally(() => (this.loading_event = false));
    },
    getSelectBusinessShipping() {
      let api =
        this.pageType === null ? selectBusinessShipping : getShareWaybillInfo;
      api(this.pageToken || this.orderId).then((response) => {
        if (!response) return;
        this.shippingInfo = response;
      });
    },
    isShowDateFun(vo, index) {
      if (index == 0) {
        return true;
      }
      return (
        this.$dayjs(vo?.createTime).format("YYYY-MM-DD") !=
        this.$dayjs(this.activityList[index - 1]?.createTime).format(
          "YYYY-MM-DD"
        )
      );
    },
  },
};
</script>

<style lang="less" scoped>
.shareOrderBox {
  width: 100%;
  position: relative;
  background: #f7f9fc;
  .background {
    width: 100%;
    height: 500px;
    position: absolute;
    transform: scale(1.1);
    top: 0;
    z-index: 0;
    object-fit: cover;
    border-radius: 0 0 100% 100%;
  }
  .bodyBox {
    position: relative;
    z-index: 1;
    padding: 0 30px 30px 30px;
    .header {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 120px;
      }
      .userInfoBox {
        display: flex;
        align-items: center;
        gap: 16px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }
    .bodyTitle {
      text-align: center;
      color: #ffffff;
      font-size: 32px;
      font-weight: 900;
      line-height: 32px;
      margin-top: 12px;
    }
    .seeMore {
      width: 240px;
      height: 48px;
      margin: 0 auto;
      background: #1475d6;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: #ffffff;
      font-size: 16px;
      margin-top: 40px;
      cursor: pointer;
      i {
        font-size: 20px;
      }
      &:hover {
        opacity: 0.9;
      }
    }
    .mapOrderBox {
      display: flex;
      gap: 20px;
      margin-top: 60px;
      .mapHistoryBox {
        border-radius: 20px;
        flex: 1;
        height: 441px;
        background: #1475d6;
        overflow: hidden;
        position: relative;
        .orderNoText {
          position: absolute;
          top: 25px;
          right: 20px;
          z-index: 2;
          color: #004C97;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
        }
        /deep/ .map_box {
          height: 100%;
        }
        .historyBox {
          width: 100%;
          height: 130px;
          position: absolute;
          bottom: 0;
          z-index: 2;
          background: #ffffff;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 40px;
          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;
            .icon {
              width: 40px;
              height: 40px;
              background: #e3e9f1;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              i {
                font-size: 25px;
                color: #ffffff;
              }
            }
            .text {
              font-size: 14px;
              font-weight: 600;
              line-height: 32px;
              color: #a3b0c6;
            }
            &.active {
              .icon {
                background: #004c97;
              }
              .text {
                color: #004c97;
              }
            }
          }
          .line {
            flex: 1;
            height: 4px;
            background: #e3e9f1;
            margin: 0 10px;
            margin-bottom: 35px;
            &.active {
              background: #004c97;
            }
          }
        }
      }
      .orderInfoBox {
        width: 305px;
        height: 580px;
        background: url(https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/shareWaybillInfoBg.png)
          no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        padding: 30px 20px;
        .title {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          color: #ffffff;
          margin-bottom: 30px;
        }
        .purchaseCompany {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 23px;
          .purchaseCompanyInfo {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            color: #ffffff;
            line-height: 17px;
            span {
              color: #ffffff;
              font-size: 14px;
              font-weight: 600;
            }
          }
          .el-image {
            width: 40px;
            height: 30px;
            border-radius: 4px;
          }
        }
        .obw-grid {
          display: grid;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: repeat(2, 1fr);
          // gap: 20px;
          .dropState {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 4px;
            &.producing {
              background: #DC3545;
            }
            &.destination {
              background: #2E4793;
            }
            &.inTransit {
              background: #48AC42;
            }
          }
        }

        .left-block {
          display: grid;
          gap: 4px;
          align-content: flex-start;
          margin-bottom: 20px;
        }

        .left-block .lb-a1 {
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 1px;
          line-height: 17px;
        }

        .left-block .lb-a2 {
          font-size: 12px;
          color: #ffffff;
          font-weight: 600;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          align-items: center;
        }
      }
    }
    
    .orderOtherBox {
      display: flex;
      gap: 30px;
      margin-top: 30px;
      .eventBox {
        flex: 1;
        background: #ffffff;
        border-radius: 20px;
        padding: 40px;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 40px;
          left: 90px;
          width: 3px;
          height: calc(100% - 80px);
          background: #73abe3;
          z-index: 0;
        }
        .eventListBox {
          position: relative;
          z-index: 1;
        }
        .data_file {
          .item_date {
            display: inline-block;
            padding: 7px 15px;
            background: #004c97;
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            border-radius: 40px;
          }
        }
        .eventLeft {
          margin-top: 12px;
          margin-bottom: 20px;
          /deep/ .active_item {
            background: #F7F9FC;
            border-radius: 20px;
            padding-right: 20px !important;
            &:hover {
              background: #F7F9FC !important;
            }
            .active_item_time {
              text-align: left !important;
            }
            .conversationBubble {
              background: transparent;
            }
            .commentTime {
              margin-right: 0;
            }
            .lmiBox {
              article {
                max-width: 100% !important;
                background: transparent !important;
                .lmi-wrapper_htmls {
                  img {
                    max-width: 200px;
                  }
                }
              }
              .icon-a-fanyi1 {
                display: none;
              }
            }
          }
        }
      }
      .right {
        width: 345px;
        & > div {
          width: 100%;
          height: 440px;
          border-radius: 20px;
          padding: 30px 45px;
          .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #ffffff;
            margin-bottom: 35px;
          }
          .infoRowDetail {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .rowItem {
              display: flex;
              color: #ffffff;
              p {
                font-size: 14px;
                line-height: 28px;
                &:first-child {
                  width: 93px;
                }
                &:last-child {
                  flex: 1;
                  font-weight: 500;
                }
              }
              .forwarderList {
                display: flex;
                flex: 1;
                .item {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 28px;
                }
              }
            }
          }
        }
        .orderInfoBox {
          background: url(https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/shareOrderInfoBg.png)
            no-repeat;
          background-size: 100% 100%;
          .logoDetail {
            display: flex;
            align-items: center;
            gap: 13px;
            margin-bottom: 37px;
            /deep/.el-avatar {
              width: 79px;
              height: 45px;
              border-radius: 4px;
              background: transparent;
              img {
                width: 100%;
              }
            }
            .content {
              .label {
                font-size: 14px;
                line-height: 28px;
                color: #ffffff;
              }
              .text {
                font-size: 14px;
                line-height: 28px;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }
        }
        .waybillInfoBox {
          background: url(https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/shareShipInfoBg.png)
            no-repeat;
          background-size: 100% 100%;
          margin-top: 30px;
          position: sticky;
          top: 30px;
          .infoRowDetail {
            gap: 30px;
          }
        }
      }
    }
  }
}
.welcomeTip {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  .welcomeBox {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 972px;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 110px;
        margin-right: 40px;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #122545;
      }
      .describe {
        font-size: 14px;
        color: #637381;
        margin-left: 12px;
      }
    }
  }
}
</style>
